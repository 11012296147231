import { redirect } from "@app/components/Auth";
import { isNotFoundError, useRequestLogin } from "@app/queries";
import { Link } from "@app/site";
import {
  AppLogoIcon,
  Body,
  Box,
  Button,
  Divider,
  GoogleIcon,
  Heading,
  Spacer,
  Text,
  useToast,
  VBox,
  ViewPort,
} from "@app/ui";
import { EmailLoginForm } from "@app/ui/Form";
import { useWindowSize } from "@app/ui/hooks";
import { SEO } from "@app/ui/seo";
import { hasActiveSession, openPopupWindow } from "@app/utils";
import { navigate, PageProps } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

export const LoginForm = ({ location, title }) => {
  const [hasSession, setHasSession] = useState<boolean>(true);

  const { failure, success } = useToast(5000);

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const hasSession = hasActiveSession();
    setHasSession(hasSession);
    if (hasSession) navigate("/app");
  }, []);

  const loginPopupName = "app-login";

  const receiveMessage = useRef((event) => {
    if (event.origin !== process.env.GATSBY_APP_BASE_URL) {
      return;
    }
    const { data, source } = event;

    if (source && source?.name == loginPopupName) {
      const params = new URLSearchParams(data);
      if (
        params.has("error_desc") &&
        params.get("error_desc") === "RessourceNotFound"
      ) {
        failure("Account not found, please Sign up first");
      } else {
        redirect({ params: data });
      }
    }
  });

  const mutation = useRequestLogin(
    (data) => {
      success("Verification email sent");
    },
    (err) => {
      if (isNotFoundError(err)) {
        failure("Account not found, please Sign up first");
      } else {
        failure("Failed to request email verification, please retry later.");
      }
    }
  );
  const { height } = useWindowSize();

  return !hasSession ? (
    <ViewPort minHeight={"650px"}>
      <Body align="center">
        <Box marginTop={10}>
          <Link to="/" ignoreHover={true} display="inline-block" mb={20}>
            <AppLogoIcon boxSize={36} maxH="70px" />
          </Link>
        </Box>

        <Box marginTop={7}>
          <Heading size="lg">{title}</Heading>
        </Box>
        <VBox marginTop={10}>
          {/* <a
            // href={
            //   `${process.env.GATSBY_API_URL}/auth/github/login${
            //     params.has("p") ? `?p=${params.get("p")}` : ""
            //   }`
            //   //  + "http://localhost:8000/app/auth/callback"
            //   // encodeURIComponent("http://localhost:8000/app/auth/callback")
            // }
          > */}
          <Button
            mb={3}
            pl={3}
            backgroundColor={"red.600"}
            borderRadius={4}
            color={"white"}
            colorScheme={"red.600"}
            cursor="pointer"
            onClick={() =>
              openPopupWindow(
                `${process.env.GATSBY_API_URL}/auth/google/login${
                  params.has("p")
                    ? `?p=${encodeURIComponent(params.get("p"))}`
                    : ""
                }`,
                loginPopupName,
                receiveMessage.current
              )
            }
          >
            <GoogleIcon boxSize={4} marginRight={2} />
            {" Continue with Google"}
          </Button>
          <Spacer h={5} />
          <Divider orientation="horizontal" />
          <EmailLoginForm
            consent={true}
            onSubmit={async (inputs: any) => {
              const { email } = inputs;
              try {
                await mutation.mutateAsync({
                  email,
                  action: "login",
                });
              } catch (err) {}
            }}
          />
        </VBox>

        <Box maxWidth={height} padding={[3, 6]} marginTop={"auto"}>
          <Box maxWidth={height} padding={[3, 6]} marginTop={"auto"}>
            <Text color={"gray.500"} align="center">
              Don't have an account?{" "}
              <Link to="/signup" fontWeight={"semibold"}>
                Sign up
              </Link>
            </Text>
          </Box>
        </Box>
      </Body>
    </ViewPort>
  ) : null;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function LoginPage({ location }: PageProps) {
  return (
    <>
      <SEO title="DeckedLink log in" />
      <QueryClientProvider client={queryClient}>
        <LoginForm location={location} title={"Log in"} />
      </QueryClientProvider>
    </>
  );
}

export default LoginPage;
